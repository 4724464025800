.breadcrumb {
    background-color: #ff672a;
    display: inline-block;
    color: #fff;
    font-family: "Open Sans";
    list-style: none;
    padding: 5px;
    display: flex;
}
.hot_event_in_cate {
    overflow: hidden;
    padding-bottom: 50px;
    border-bottom: 1px solid #e8e8e8;
    font-family: open sans;
}
.sub_event_cate h3 {
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 20px;
    font-family: open sans;
}
.sub_event_cate .ticket_hot_cate {
    font-size: 14px;
    font-family: open sans;
    line-height: 17px;
    overflow: hidden;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
}
.hot_event_in_cate .hot_cate_info {
    padding-top: 20px;
    font-weight: 400;
    text-transform: lowercase;
}