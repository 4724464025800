.gradient-custom-3 {
    /* fallback for old browsers */
    background: #2e3254;
    
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #2e3254, #2e3254);
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #2e3254, #2e3254)
    }
    .gradient-custom-4 {
    /* fallback for old browsers */
    background: #2e3254;
    
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #2e3254, #2e3254);
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #2e3254, #2e3254)
    }

